import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('currentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [token, setToken] = useState(() => localStorage.getItem('token'));

  // Fetch the current user if the token is valid
  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        try {
          const response = await axios.get('https://api.ijeaweleinvest.com/users/current_user/', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
          localStorage.setItem('currentUser', JSON.stringify(response.data));
        } catch (error) {
          console.error('Token expired or invalid. Logging out user.', error);
          logout(); // Automatically log out if the token is invalid
        }
      }
    };
    fetchUser();
  }, [token]);

  // Login function
  const login = async (credentials, navigate) => {
    try {
      const response = await axios.post('https://api.ijeaweleinvest.com/api/auth/token/', credentials);
      if (response.status === 200) {
        const { access } = response.data;
        setToken(access);
        localStorage.setItem('token', access);

        const userResponse = await axios.get('https://api.ijeaweleinvest.com/users/current_user/', {
          headers: { Authorization: `Bearer ${access}` },
        });
        setUser(userResponse.data);
        localStorage.setItem('currentUser', JSON.stringify(userResponse.data));
        navigate('/overview');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Logout function
  const logout = async (navigate) => {
    try {
      if (token) {
        await axios.post('https://api.ijeaweleinvest.com/users/logout/', {}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("Token invalidated on server.");
      }
    } catch (error) {
      console.error("Server logout failed:", error);
    } finally {
      // Clear all authentication state and local storage
      setUser(null);
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');

      // Ensure navigation works correctly
      if (navigate) {
        navigate('/signin');
      }
    }
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
