// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const IdentityVerification = ({ profileId, showMessage }) => {
//   const [idFile, setIdFile] = useState(null);
//   const [idFilePreview, setIdFilePreview] = useState(null);
//   const [faceFile, setFaceFile] = useState(null);
//   const [faceFilePreview, setFaceFilePreview] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [formDisabled, setFormDisabled] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [messageType, setMessageType] = useState("");

//   useEffect(() => {
//     const checkVerificationStatus = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           showMessage("Authentication error. Please log in again.", "error");
//           return;
//         }

//         // Fetch current user data
//         const userResponse = await axios.get(
//           "https://api.ijeaweleinvest.com/users/current_user/",
//           { headers: { Authorization: `Bearer ${token}` } }
//         );

//         const userVerified = userResponse.data.verification_status;
//         const userId = userResponse.data.user;
        
//         setVerified(userVerified);

//         if (userVerified) {
//           setFormDisabled(true);
//           setMessage("You are already verified. No further action is needed.");
//           setMessageType("success");
//           return;
//         }

//         // Fetch KYC details
//         const kycResponse = await axios.get(
//           "https://api.ijeaweleinvest.com/kyc/",
//           { headers: { Authorization: `Bearer ${token}` } }
//         );

//         const kycDetails = kycResponse.data;
//         if (kycDetails.length > 0) {
//           const kycStatus = kycDetails[0].kyc_verification_status;
//           if (kycStatus === "In Progress") {
//             setFormDisabled(true);
//             setMessage("Verification in progress. Please wait for confirmation.");
//             setMessageType("info");
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching verification or KYC status:", error);
//         showMessage("Failed to fetch verification status. Please try again.", "error");
//       }
//     };

//     checkVerificationStatus();
//   }, [showMessage]);

//   const handleFileChange = (setter, previewSetter) => (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setter(file);
//       previewSetter(URL.createObjectURL(file));
//     }
//   };

//   const handleSubmit = async () => {
//     if (!idFile || !faceFile) {
//       setMessage("Please upload both an ID and a facial image.");
//       setMessageType("error");
//       setTimeout(() => {
//         setMessage(null);
//         setMessageType("");
//       }, 5000);
//       return;
//     }

//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("identity_card", idFile);
//       formData.append("facial_recognition", faceFile);
//       formData.append("user", profileId);
//       formData.append("kyc_verification_status", "In Progress");

//       const token = localStorage.getItem("token");
//       if (!token) {
//         setMessage("Authentication error. Please log in again.");
//         setMessageType("error");
//         setLoading(false);
//         return;
//       }

//       await axios.post("https://api.ijeaweleinvest.com/kyc/", formData, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       setMessage("KYC submitted successfully. Verification pending.");
//       setMessageType("success");
//       setFormDisabled(true);
//     } catch {
//       setMessage("Submission failed. Please try again.");
//       setMessageType("error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (verified) {
//     return <p>You are already verified. No further action is needed.</p>;
//   }

//   if (formDisabled) {
//     return <p>{message}</p>;
//   }

//   return (
//     <div className="kyc-form">
//       <h5>Identity Verification</h5>
//       <div className="kyc-upload">
//         <label htmlFor="id-upload" className="kyc-upload-label">
//           Upload your Government ID card
//         </label>
//         <input
//           type="file"
//           id="id-upload"
//           className="kyc-upload-input"
//           accept="image/*"
//           onChange={handleFileChange(setIdFile, setIdFilePreview)}
//           disabled={formDisabled}
//         />
//         {idFilePreview && (
//           <div className="image-preview">
//             <img src={idFilePreview} alt="ID Preview" />
//             <p>ID image selected</p>
//           </div>
//         )}
//       </div>

//       <div className="kyc-upload">
//         <label htmlFor="face-upload" className="kyc-upload-label">
//           Upload a clear photo of your face holding the ID
//         </label>
//         <input
//           type="file"
//           id="face-upload"
//           className="kyc-upload-input"
//           accept="image/*"
//           onChange={handleFileChange(setFaceFile, setFaceFilePreview)}
//           disabled={formDisabled}
//         />
//         {faceFilePreview && (
//           <div className="image-preview">
//             <img src={faceFilePreview} alt="Face Preview" />
//             <p>Facial image selected</p>
//           </div>
//         )}
//       </div>

//       {message && <p className={`kyc-message ${messageType}`}>{message}</p>}

//       <button
//         onClick={handleSubmit}
//         className="id-submit-button"
//         disabled={formDisabled || loading}
//       >
//         {loading ? "Submitting..." : "Submit"}
//       </button>
//     </div>
//   );
// };

// export default IdentityVerification;



// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const IdentityVerification = ({ profileId, showMessage }) => {
//   const [idFile, setIdFile] = useState(null);
//   const [idFilePreview, setIdFilePreview] = useState(null);
//   const [faceFile, setFaceFile] = useState(null);
//   const [faceFilePreview, setFaceFilePreview] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [formDisabled, setFormDisabled] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [messageType, setMessageType] = useState("");

//   useEffect(() => {
//     const checkVerificationStatus = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           showMessage("Authentication error. Please log in again.", "error");
//           return;
//         }

//         // Fetch current user data
//         const userResponse = await axios.get(
//           "https://api.ijeaweleinvest.com/users/current_user/",
//           { headers: { Authorization: `Bearer ${token}` } }
//         );

//         const userVerified = userResponse.data.verification_status;
//         const kycDetails = userResponse.data.kyc;
        
//         setVerified(userVerified);

//         if (userVerified) {
//           setFormDisabled(true);
//           setMessage("You are already verified. No further action is needed.");
//           setMessageType("success");
//           return;
//         }

//         if (kycDetails) {
//           const kycStatus = kycDetails.kyc_verification_status;
//           if (kycStatus === "In Progress") {
//             setFormDisabled(true);
//             setMessage("Verification in progress. Please wait for confirmation.");
//             setMessageType("info");
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching verification or KYC status:", error);
//         showMessage("Failed to fetch verification status. Please try again.", "error");
//       }
//     };

//     checkVerificationStatus();
//   }, [showMessage]);

//   const handleFileChange = (setter, previewSetter) => (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setter(file);
//       previewSetter(URL.createObjectURL(file));
//     }
//   };

//   // const handleSubmit = async () => {
//   //   if (!idFile || !faceFile) {
//   //     setMessage("Please upload both an ID and a facial image.");
//   //     setMessageType("error");
//   //     setTimeout(() => {
//   //       setMessage(null);
//   //       setMessageType("");
//   //     }, 5000);
//   //     return;
//   //   }

//   //   setLoading(true);
//   //   try {
//   //     const formData = new FormData();
//   //     formData.append("identity_card", idFile);
//   //     formData.append("facial_recognition", faceFile);
//   //     formData.append("user", profileId);
//   //     formData.append("kyc_verification_status", "In Progress");

//   //     const token = localStorage.getItem("token");
//   //     if (!token) {
//   //       setMessage("Authentication error. Please log in again.");
//   //       setMessageType("error");
//   //       setLoading(false);
//   //       return;
//   //     }

//   //     await axios.post("https://api.ijeaweleinvest.com/kyc/", formData, {
//   //       headers: { Authorization: `Bearer ${token}` },
//   //     });

//   //     setMessage("KYC submitted successfully. Verification pending.");
//   //     setMessageType("success");
//   //     setFormDisabled(true);
//   //   } catch {
//   //     setMessage("Submission failed. Please try again.");
//   //     setMessageType("error");
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };
//   const handleSubmit = async () => {
//     if (!idFile || !faceFile) {
//       setMessage("Please upload both an ID and a facial image.");
//       setMessageType("error");
//       setTimeout(() => {
//         setMessage(null);
//         setMessageType("");
//       }, 5000);
//       return;
//     }
  
//     setLoading(true);
//     try {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         setMessage("Authentication error. Please log in again.");
//         setMessageType("error");
//         setLoading(false);
//         return;
//       }
  
//       // Convert files to base64 strings
//       const toBase64 = (file) => new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//       });
  
//       const idFileBase64 = await toBase64(idFile);
//       const faceFileBase64 = await toBase64(faceFile);
  
//       const payload = {
//         identity_card: idFileBase64,
//         facial_recognition: faceFileBase64,
//         user: profileId,
//         kyc_verification_status: "In Progress",
//       };
  
//       // Log payload to verify content
//       console.log("Payload:", payload);
  
//       const response = await axios.post("https://api.ijeaweleinvest.com/kyc/", payload, {
//         headers: { 
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json"
//         },
//       });
  
//       setMessage("KYC submitted successfully. Verification pending.");
//       setMessageType("success");
//       setFormDisabled(true);
//     } catch (error) {
//       console.error("Submission failed:", error.response);
//       setMessage("Submission failed. Please try again.");
//       setMessageType("error");
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   if (verified) {
//     return <p>You are already verified. No further action is needed.</p>;
//   }

//   if (formDisabled) {
//     return <p>{message}</p>;
//   }

//   return (
//     <div className="kyc-form">
//       <h5>Identity Verification</h5>
//       <div className="kyc-upload">
//         <label htmlFor="id-upload" className="kyc-upload-label">
//           Upload your Government ID card
//         </label>
//         <input
//           type="file"
//           id="id-upload"
//           className="kyc-upload-input"
//           accept="image/*"
//           onChange={handleFileChange(setIdFile, setIdFilePreview)}
//           disabled={formDisabled}
//         />
//         {idFilePreview && (
//           <div className="image-preview">
//             <img src={idFilePreview} alt="ID Preview" />
//             <p>ID image selected</p>
//           </div>
//         )}
//       </div>

//       <div className="kyc-upload">
//         <label htmlFor="face-upload" className="kyc-upload-label">
//           Upload a clear photo of your face holding the ID
//         </label>
//         <input
//           type="file"
//           id="face-upload"
//           className="kyc-upload-input"
//           accept="image/*"
//           onChange={handleFileChange(setFaceFile, setFaceFilePreview)}
//           disabled={formDisabled}
//         />
//         {faceFilePreview && (
//           <div className="image-preview">
//             <img src={faceFilePreview} alt="Face Preview" />
//             <p>Facial image selected</p>
//           </div>
//         )}
//       </div>

//       {message && <p className={`kyc-message ${messageType}`}>{message}</p>}

//       <button
//         onClick={handleSubmit}
//         className="id-submit-button"
//         disabled={formDisabled || loading}
//       >
//         {loading ? "Submitting..." : "Submit"}
//       </button>
//     </div>
//   );
// };

// export default IdentityVerification;



import React, { useState, useEffect } from "react";
import axios from "axios";

const IdentityVerification = ({ profileId, showMessage }) => {
  const [idFile, setIdFile] = useState(null);
  const [idFilePreview, setIdFilePreview] = useState(null);
  const [faceFile, setFaceFile] = useState(null);
  const [faceFilePreview, setFaceFilePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    console.log("Profile ID from Parent:", profileId);
    const checkVerificationStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          showMessage("Authentication error. Please log in again.", "error");
          return;
        }

        // Fetch current user data
        const userResponse = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const user = userResponse.data.user;
        // console.log(user)
        const userVerified = userResponse.data.verification_status;
        const kycDetails = userResponse.data.kyc;
        
        setVerified(userVerified);

        if (userVerified) {
          setFormDisabled(true);
          setMessage("You are already verified. No further action is needed.");
          setMessageType("success");
          return;
        }

        if (kycDetails) {
          const kycStatus = kycDetails.kyc_verification_status;
          if (kycStatus === "In Progress") {
            setFormDisabled(true);
            setMessage("Verification in progress. Please wait for confirmation.");
            setMessageType("info");
          }
        }
      } catch (error) {
        console.error("Error fetching verification or KYC status:", error);
        showMessage("Failed to fetch verification status. Please try again.", "error");
      }
    };

    checkVerificationStatus();
  }, [showMessage]);

  const handleFileChange = (setter, previewSetter) => (e) => {
    const file = e.target.files[0];
    if (file) {
      setter(file);
      previewSetter(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (!idFile || !faceFile) {
      setMessage("Please upload both an ID and a facial image.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType("");
      }, 5000);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("identity_card", idFile);
      formData.append("facial_recognition", faceFile);
      formData.append("user", profileId);
      formData.append("kyc_verification_status", "In Progress");

      const token = localStorage.getItem("token");
      if (!token) {
        setMessage("Authentication error. Please log in again.");
        setMessageType("error");
        setLoading(false);
        return;
      }

      // Log formData to verify content
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      await axios.post("https://api.ijeaweleinvest.com/kyc/", formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage("KYC submitted successfully. Verification pending.");
      setMessageType("success");
      setFormDisabled(true);
    } catch (error) {
      console.error("Submission failed:", error.response);
      setMessage("Submission failed. Please try again.");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  if (verified) {
    return <p>You are already verified. No further action is needed.</p>;
  }

  if (formDisabled) {
    return <p>{message}</p>;
  }

  return (
    <div className="kyc-form">
      <h5>Identity Verification</h5>
      <div className="kyc-upload">
        <label htmlFor="id-upload" className="kyc-upload-label">
          Upload your Government ID card
        </label>
        <input
          type="file"
          id="id-upload"
          className="kyc-upload-input"
          accept="image/*"
          onChange={handleFileChange(setIdFile, setIdFilePreview)}
          disabled={formDisabled}
        />
        {idFilePreview && (
          <div className="image-preview">
            <img src={idFilePreview} alt="ID Preview" />
            <p>ID image selected</p>
          </div>
        )}
      </div>

      <div className="kyc-upload">
        <label htmlFor="face-upload" className="kyc-upload-label">
          Upload a clear photo of your face holding the ID
        </label>
        <input
          type="file"
          id="face-upload"
          className="kyc-upload-input"
          accept="image/*"
          onChange={handleFileChange(setFaceFile, setFaceFilePreview)}
          disabled={formDisabled}
        />
        {faceFilePreview && (
          <div className="image-preview">
            <img src={faceFilePreview} alt="Face Preview" />
            <p>Facial image selected</p>
          </div>
        )}
      </div>

      {message && <p className={`kyc-message ${messageType}`}>{message}</p>}

      <button
        onClick={handleSubmit}
        className="id-submit-button"
        disabled={formDisabled || loading}
      >
        {loading ? "Submitting..." : "Submit"}
      </button>
    </div>
  );
};

export default IdentityVerification;









// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const IdentityVerification = ({ profileId, showMessage }) => {
//   const [idFile, setIdFile] = useState(null);
//   const [idFilePreview, setIdFilePreview] = useState(null);
//   const [faceFile, setFaceFile] = useState(null);
//   const [faceFilePreview, setFaceFilePreview] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [formDisabled, setFormDisabled] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [messageType, setMessageType] = useState("");

//   useEffect(() => {
//     const checkVerificationStatus = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           showMessage("Authentication error. Please log in again.", "error");
//           return;
//         }

//         // Fetch current user data
//         const userResponse = await axios.get(
//           "https://api.ijeaweleinvest.com/users/current_user/",
//           { headers: { Authorization: `Bearer ${token}` } }
//         );

//         const user = userResponse.data.user;
//         const userVerified = userResponse.data.verification_status;
        
//         setVerified(userVerified);

//         if (userVerified) {
//           setFormDisabled(true);
//           setMessage("You are already verified. No further action is needed.");
//           setMessageType("success");
//           return;
//         }

//         // Fetch KYC details
//         const kycResponse = await axios.get(
//           "https://api.ijeaweleinvest.com/kyc/",
//           { headers: { Authorization: `Bearer ${token}` } }
//         );

//         const kycDetails = kycResponse.data.find(kyc => kyc.user === user);
//         if (kycDetails) {
//           const kycStatus = kycDetails.kyc_verification_status;
//           if (kycStatus === "In Progress") {
//             setFormDisabled(true);
//             setMessage("Verification in progress. Please wait for confirmation.");
//             setMessageType("info");
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching verification or KYC status:", error);
//         showMessage("Failed to fetch verification status. Please try again.", "error");
//       }
//     };

//     checkVerificationStatus();
//   }, [showMessage]);

//   const handleFileChange = (setter, previewSetter) => (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setter(file);
//       previewSetter(URL.createObjectURL(file));
//     }
//   };

//   const handleSubmit = async () => {
//     if (!idFile || !faceFile) {
//       setMessage("Please upload both an ID and a facial image.");
//       setMessageType("error");
//       setTimeout(() => {
//         setMessage(null);
//         setMessageType("");
//       }, 5000);
//       return;
//     }

//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("identity_card", idFile);
//       formData.append("facial_recognition", faceFile);
//       formData.append("user", profileId);
//       formData.append("kyc_verification_status", "In Progress");

//       const token = localStorage.getItem("token");
//       if (!token) {
//         setMessage("Authentication error. Please log in again.");
//         setMessageType("error");
//         setLoading(false);
//         return;
//       }

//       await axios.post("https://api.ijeaweleinvest.com/kyc/", formData, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       setMessage("KYC submitted successfully. Verification pending.");
//       setMessageType("success");
//       setFormDisabled(true);
//     } catch {
//       setMessage("Submission failed. Please try again.");
//       setMessageType("error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (verified) {
//     return <p>You are already verified. No further action is needed.</p>;
//   }

//   if (formDisabled) {
//     return <p>{message}</p>;
//   }

//   return (
//     <div className="kyc-form">
//       <h5>Identity Verification</h5>
//       <div className="kyc-upload">
//         <label htmlFor="id-upload" className="kyc-upload-label">
//           Upload your Government ID card
//         </label>
//         <input
//           type="file"
//           id="id-upload"
//           className="kyc-upload-input"
//           accept="image/*"
//           onChange={handleFileChange(setIdFile, setIdFilePreview)}
//           disabled={formDisabled}
//         />
//         {idFilePreview && (
//           <div className="image-preview">
//             <img src={idFilePreview} alt="ID Preview" />
//             <p>ID image selected</p>
//           </div>
//         )}
//       </div>

//       <div className="kyc-upload">
//         <label htmlFor="face-upload" className="kyc-upload-label">
//           Upload a clear photo of your face holding the ID
//         </label>
//         <input
//           type="file"
//           id="face-upload"
//           className="kyc-upload-input"
//           accept="image/*"
//           onChange={handleFileChange(setFaceFile, setFaceFilePreview)}
//           disabled={formDisabled}
//         />
//         {faceFilePreview && (
//           <div className="image-preview">
//             <img src={faceFilePreview} alt="Face Preview" />
//             <p>Facial image selected</p>
//           </div>
//         )}
//       </div>

//       {message && <p className={`kyc-message ${messageType}`}>{message}</p>}

//       <button
//         onClick={handleSubmit}
//         className="id-submit-button"
//         disabled={formDisabled || loading}
//       >
//         {loading ? "Submitting..." : "Submit"}
//       </button>
//     </div>
//   );
// };

// export default IdentityVerification;
