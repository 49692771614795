import React, { useState } from "react";
import axios from "axios";
import "../Styles/Security.css";
import { useAuth } from "./AuthContext"; // Import the custom hook

const PinReset = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [pinCreated, setPinCreated] = useState(false);

  const { user } = useAuth(); // Call the custom hook directly

  const handlePinReset = async (e) => {
    e.preventDefault();

    // Validate empty fields
    if (!oldPin || !newPin || !confirmNewPin) {
      setMessage({ type: "error", text: "All fields are required" });
      setTimeout(() => setMessage(null), 5000);
      return;
    }

    // Validate matching new PIN and confirmation PIN
    if (newPin !== confirmNewPin) {
      setMessage({ type: "error", text: "PINs do not match" });
      setTimeout(() => setMessage(null), 5000);
      return;
    }

    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "https://api.ijeaweleinvest.com/users/set_transaction_pin/",
        {
          user: user.user,
          current_pin: oldPin,
          new_pin: newPin,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage({ type: "success", text: "PIN reset successfully" });
      setPinCreated(true);

      // Clear form fields
      setOldPin("");
      setNewPin("");
      setConfirmNewPin("");
    } catch (error) {
      const errorText =
        error.response?.data?.error || "An error occurred. Please try again.";
      setMessage({ type: "error", text: errorText });
    } finally {
      setIsLoading(false);
      setTimeout(() => setMessage(null), 7000); // Clear message after 7 seconds
    }
  };

  return (
    <div className="accordion-item">
      <button
        className="accordion-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Reset PIN
      </button>
      {isExpanded && (
        <form onSubmit={handlePinReset}>
          <input
            type="password"
            placeholder="Enter Old PIN"
            value={oldPin}
            onChange={(e) => setOldPin(e.target.value)}
          />
          <input
            type="password"
            placeholder="New PIN"
            value={newPin}
            onChange={(e) => setNewPin(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm New PIN"
            value={confirmNewPin}
            onChange={(e) => setConfirmNewPin(e.target.value)}
          />
          <button type="submit" disabled={isLoading || pinCreated}>
            {isLoading ? "Resetting..." : "Reset PIN"}
          </button>
        </form>
      )}
      {message && (
        <p
          className={`message ${
            message.type === "error" ? "error" : "success"
          }`}
        >
          {message.text}
        </p>
      )}
    </div>
  );
};

export default PinReset;
