// app without auth context wrapper

// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useLocation,
// } from "react-router-dom";
// import { useEffect } from "react";
// import {jwtDecode} from "jwt-decode";
// import SignUp from "./Pages/SignUp";
// import SignIn from "./Pages/SignIn";
// import Overview from "./Pages/Overview";
// import Transactions from "./Pages/Transactions";
// import Assets from "./Pages/Assets";
// import Profile from "./Pages/Profile";
// import Logout from "./Pages/Logout";
// import ForgotPassword from "./Pages/ForgotPassword";
// import AssetDetails from "./Pages/AssetDetails";
// import Withdrawal from "./Pages/Withdrawal";
// import Investment from "./Pages/Investment";
// import MyInvestment from "./Pages/MyInvestment";
// import OTPVerification from "./Pages/OTPVerification";
// import ProtectedRoute from "./Components/ProtectedRoute";
// import { getToken } from "./Utilities/authUtils";

// function App() {
//   // This must be inside Router component
//   function BodyMarginController() {
//     const location = useLocation();

//     useEffect(() => {
//       // Remove the margin-top for SignUp and SignIn pages
//       if (
//         location.pathname === "/signup" ||
//         location.pathname === "/signin" ||
//         location.pathname === "/forgotpassword" ||
//         location.pathname === "/otpverification"
//       ) {
//         document.body.style.marginTop = "0px";
//       } else {
//         document.body.style.marginTop = "70px";
//       }
//     }, [location]);

//     return null; // This component only controls body styles, no UI
//   }

//   // Function to check if the user is authenticated
//   const isAuthenticated = () => {
//     const token = getToken();
//     if (!token) return false;

//     try {
//       const decoded = jwtDecode(token);
//       // Check if token is expired
//       return decoded.exp && decoded.exp * 1000 > Date.now();
//     } catch (error) {
//       return false;
//     }
//   };

//   return (
//     <>
//       <Router>
//         {/* This component will handle body margin-top dynamically */}
//         <BodyMarginController />
//         <Routes>
//           {/* public routes */}
//           <Route
//             path="/"
//             element={
//               isAuthenticated() ? <Navigate to="/overview" /> : <Navigate to="/signup" />
//             }
//           />
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/signin" element={<SignIn />} />
//           <Route path="otpverification" element={ <OTPVerification/> } />
//           <Route path="/forgotpassword" element={<ForgotPassword />} />

//           {/* protected routes */}
//           <Route
//             path="/overview"
//             element={
//               <ProtectedRoute>
//                 <Overview />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/withdrawal"
//             element={
//               <ProtectedRoute>
//                 <Withdrawal />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/transactions"
//             element={
//               <ProtectedRoute>
//                 <Transactions />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/assets"
//             element={
//               <ProtectedRoute>
//                 <Assets />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/asset/:id"
//             element={
//               <ProtectedRoute>
//                 <AssetDetails />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/myinvestment"
//             element={
//               <ProtectedRoute>
//                 <MyInvestment />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/profile"
//             element={
//               <ProtectedRoute>
//                 <Profile />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/logout"
//             element={
//               <ProtectedRoute>
//                 <Logout />
//               </ProtectedRoute>
//             }
//           />
//           <Route
//             path="/investment"
//             element={
//               <ProtectedRoute>
//                 <Investment />
//               </ProtectedRoute>
//             }
//           />
//         </Routes>
//       </Router>
//     </>
//   );
// }

// export default App;



import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import Overview from "./Pages/Overview";
import Transactions from "./Pages/Transactions";
import Assets from "./Pages/Assets";
import Profile from "./Pages/Profile";
import Logout from "./Pages/Logout";
import ForgotPassword from "./Pages/ForgotPassword";
import AssetDetails from "./Pages/AssetDetails";
import Withdrawal from "./Pages/Withdrawal";
import Investment from "./Pages/Investment";
import MyInvestment from "./Pages/MyInvestment";
import OTPVerification from "./Pages/OTPVerification";
import ProtectedRoute from "./Components/ProtectedRoute";
import { getToken } from "./Utilities/authUtils";
import { AuthProvider } from "./Components/AuthContext"; // Import the AuthProvider

function App() {
  // This must be inside Router component
  function BodyMarginController() {
    const location = useLocation();

    useEffect(() => {
      // Remove the margin-top for SignUp and SignIn pages
      if (
        location.pathname === "/signup" ||
        location.pathname === "/signin" ||
        location.pathname === "/forgotpassword" ||
        location.pathname === "/otpverification"
      ) {
        document.body.style.marginTop = "0px";
      } else {
        document.body.style.marginTop = "70px";
      }
    }, [location]);

    return null; // This component only controls body styles, no UI
  }

  // Function to check if the user is authenticated
  const isAuthenticated = () => {
    const token = getToken();
    if (!token) return false;

    try {
      const decoded = jwtDecode(token);
      // Check if token is expired
      return decoded.exp && decoded.exp * 1000 > Date.now();
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <AuthProvider> {/* Wrap your application with AuthProvider */}
        <Router>
          {/* This component will handle body margin-top dynamically */}
          <BodyMarginController />
          <Routes>
            {/* public routes */}
            <Route
              path="/"
              element={
                isAuthenticated() ? <Navigate to="/overview" /> : <Navigate to="/signup" />
              }
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="otpverification" element={ <OTPVerification/> } />
            <Route path="/forgotpassword" element={<ForgotPassword />} />

            {/* protected routes */}
            <Route
              path="/overview"
              element={
                <ProtectedRoute>
                  <Overview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/withdrawal"
              element={
                <ProtectedRoute>
                  <Withdrawal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transactions"
              element={
                <ProtectedRoute>
                  <Transactions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <ProtectedRoute>
                  <Assets />
                </ProtectedRoute>
              }
            />
            <Route
              path="/asset/:id"
              element={
                <ProtectedRoute>
                  <AssetDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/myinvestment"
              element={
                <ProtectedRoute>
                  <MyInvestment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />
            <Route
              path="/investment"
              element={
                <ProtectedRoute>
                  <Investment />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
