// import { useState, useEffect } from "react";
// import { usePaystackPayment } from "react-paystack";

// function PaystackDeposit({ userEmail, setDepositOption }) {
//   const [depositAmount, setDepositAmount] = useState("");
//   const initializePayment = usePaystackPayment({
//     email: userEmail,
//     amount: depositAmount * 100,
//     publicKey: "pk_live_2af1d277190e7680d0309464e7576bf4213d8464",
//   });

//   const handlePaystackPayment = () => {
//     if (depositAmount > 0) {
//       initializePayment(
//         (reference) => {
//           console.log("Payment successful", reference);
//           setDepositOption(null);
//         },
//         () => {
//           console.log("Payment closed");
//         }
//       );
//     } else {
//       alert("Please enter a valid amount.");
//     }
//   };

//   return (
//     <div className="overlay">
//       <div className="deposit-popup">
//         <h3>Pay with Paystack</h3>
//         {/* <input
//           type="number"
//           placeholder="Enter amount"
//           value={depositAmount}
//           onChange={(e) => setDepositAmount(Number(e.target.value))}
//           className="deposit-input"
//         /> */}

//         <input
//           type="text"
//           placeholder="Enter amount"
//           value={
//             depositAmount !== ""
//               ? `₦${Number(depositAmount).toLocaleString("en-NG")}`
//               : ""
//           }
//           onChange={(e) => {
//             const value = e.target.value.replace(/₦|,/g, ""); // Remove Naira sign and commas
//             if (!isNaN(value) && value >= 0) {
//               setDepositAmount(value);
//             }
//           }}
//           onFocus={(e) => {
//             if (e.target.value === "") {
//               setDepositAmount("");
//             }
//           }}
//           className="deposit-input"
//         />

//         <button onClick={handlePaystackPayment} className="confirm-deposit-btn">
//           Proceed to Paystack
//         </button>
//         <button onClick={() => setDepositOption(null)} className="cancel-btn">
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// }

// export default PaystackDeposit


// import { useState } from "react";
// import { usePaystackPayment } from "react-paystack";

// function PaystackDeposit({ userEmail, setDepositOption }) {
//   const [depositAmount, setDepositAmount] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const initializePayment = usePaystackPayment({
//     email: userEmail,
//     amount: depositAmount * 100,
//     publicKey: "pk_live_2af1d277190e7680d0309464e7576bf4213d8464",
//   });

//   const handlePaystackPayment = () => {
//     const amount = Number(depositAmount.replace(/₦|,/g, ""));

//     if (isNaN(amount) || amount <= 0) {
//       setErrorMessage("Please enter a valid amount.");
//       setTimeout(() => setErrorMessage(""), 5000); // Clear the error message after 5 seconds
//       return;
//     }

//     if (amount > 500000) {
//       setErrorMessage(
//         "Amount exceeds ₦500,000. Redirecting to bank deposit..."
//       );
//       setTimeout(() => {
//         setDepositOption("manual");
//       }, 3000); // Redirect to manual deposit after 3 seconds
//       return;
//     }

//     initializePayment(
//       (reference) => {
//         console.log("Payment successful", reference);
//         setDepositOption(null);
//       },
//       () => {
//         console.log("Payment closed");
//       }
//     );
//   };

//   return (
//     <div className="overlay">
//       <div className="deposit-popup">
//         {/* <h3>Pay with Paystack</h3> */}
//         <h3>Continue with Deposit</h3>
//         {errorMessage && <p className="error-message">{errorMessage}</p>}
//         <input
//           type="text"
//           placeholder="Enter amount"
//           value={
//             depositAmount !== ""
//               ? `₦${Number(depositAmount.replace(/₦|,/g, "")).toLocaleString(
//                   "en-NG"
//                 )}`
//               : ""
//           }
//           onChange={(e) => {
//             const value = e.target.value.replace(/₦|,/g, ""); // Remove Naira sign and commas
//             if (!isNaN(value) && value >= 0) {
//               setDepositAmount(value);
//             }
//           }}
//           onFocus={(e) => {
//             if (e.target.value === "") {
//               setDepositAmount("");
//             }
//           }}
//           className="deposit-input"
//         />
//         <button onClick={handlePaystackPayment} className="confirm-deposit-btn">
//           Proceed to Paystack
//         </button>
//         <button onClick={() => setDepositOption(null)} className="cancel-btn">
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// }

// export default PaystackDeposit;


import { usePaystackPayment } from "react-paystack";

function PaystackDeposit({ userEmail, depositAmount, setDepositOption }) {
  const initializePayment = usePaystackPayment({
    email: userEmail,
    amount: depositAmount * 100,
    publicKey: "pk_live_2af1d277190e7680d0309464e7576bf4213d8464",
  });

  const handlePaystackPayment = () => {
    initializePayment(
      (reference) => {
        console.log("Payment successful", reference);
        setDepositOption(null);
      },
      () => {
        console.log("Payment closed");
      }
    );
  };

  return (
    <div className="overlay">
      <div className="deposit-popup">
        <h3>Proceed with Paystack</h3>
        <p>Amount: ₦{depositAmount.toLocaleString("en-NG")}</p>
        <button onClick={handlePaystackPayment} className="confirm-deposit-btn">
          Proceed to Paystack
        </button>
        <button onClick={() => setDepositOption(null)} className="cancel-btn">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default PaystackDeposit;
