// initial login without auth context

// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Logo from "../Assets/logo.svg";
// import TextInput from "../Components/TextInput";
// import PasswordInput from "../Components/PasswordInput";
// // import Loader from 'react-loader-spinner'; // for the loading effect
// import { RevolvingDot } from "react-loader-spinner";
// import "../Styles/SignUp.css";

// function SignIn() {
//   const [isAnimated, setIsAnimated] = useState(false);
//   const [isLoading, setIsLoading] = useState(false); //loading effect state

//   // Page animation
//   useEffect(() => {
//     setIsAnimated(true);
//   }, []);

//   // This will redirect authenticated user to dashboard automatically
//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       navigate("/overview");
//     }
//   }, []);

//   // Form state for email and password
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate(); // Used to navigate on successful login

//   // Sign in form validation and submission
//   async function validateSignIn(e) {
//     e.preventDefault();
//     setIsLoading(true);
//     setErrorMessage(""); // Clear previous errors

//     // Validate email and password
//     const emailRegex = /^\w+@\w+\.\w+$/;
//     if (!emailRegex.test(email)) {
//       setErrorMessage("Please enter a valid email address.");
//       setIsLoading(false);
//       return;
//     }
//     if (password.length < 8) {
//       setErrorMessage("Password must be at least 8 characters long.");
//       setIsLoading(false);
//       return;
//     }

//     // Prepare login data
//     const loginData = { 
//       username: email.toLocaleLowerCase(), 
//       password 
//     };

//     try {
//       // Send login request
//       const response = await axios.post(
//         "https://api.ijeaweleinvest.com/api/auth/token/",
//         loginData
//       );

//       if (response.status === 200) {
//         setIsLoading(false);
//         localStorage.setItem("token", response.data.access);
//         setPassword(""); //clears password after signing in
//         navigate("/overview");
//       }
//     } catch (error) {
//       setIsLoading(false);

//       // Error handling based on the type
//       if (error.response) {
//         if (error.response.status === 400 || error.response.status === 401) {
//           setErrorMessage("Invalid email or password. Please try again.");
//         } else if (error.response.status >= 500) {
//           setErrorMessage("Server error. Please try again later.");
//         }
//       } else if (error.request) {
//         setErrorMessage("Network error. Check your connection and try again.");
//       } else {
//         setErrorMessage("An unexpected error occurred. Please try again.");
//       }

//       console.error("Sign-in error:", error.message);
//     }
//   }

//   return (
//     <div className="signup-container no-topnav-page">
//       <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
//         <img className="centered-logo" src={Logo} alt="Company logo" />
//         <h1 className="hello-text">Hello</h1>
//         <h2 className="welcome-text">
//           Login to your <br /> Ijeawele Account
//         </h2>
//       </div>
//       <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
//         <div className="form-card">
//           <h2>Sign In</h2>
//           <p>Welcome back to Ijeawele Investments</p>
//           <form onSubmit={validateSignIn}>
//             <TextInput
//               label="Email"
//               type="email"
//               id="email"
//               placeholder="Enter your email"
//               required
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <PasswordInput
//               id="password"
//               placeholder="Enter your password"
//               label="Password"
//               required
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />

//             <Link to="/forgotpassword" className="forgot-password">
//               Forgot Password?
//             </Link>

//             {/* Show error message if there's an issue */}
//             {errorMessage && <p className="error-message">{errorMessage}</p>}

//             {/* page loading indication */}
//             {isLoading && (
//               <RevolvingDot
//                 type="RevolvingDot"
//                 color="#00BFFF"
//                 height={5}
//                 width={5}
//                 timeout={3000} //3 secs
//               />
//             )}

//             <button type="submit">Sign In</button>
//           </form>

//           <p className="create-account-text">
//             Are you new to Ijeawele? <Link to="/">Create an Account</Link>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SignIn;


// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Logo from "../Assets/logo.svg";
// import TextInput from "../Components/TextInput";
// import PasswordInput from "../Components/PasswordInput";
// import { RevolvingDot } from "react-loader-spinner";
// import "../Styles/SignUp.css";
// import { useAuth } from '../Components/AuthContext'; // Adjust the path as needed

// function SignIn() {
//   const [isAnimated, setIsAnimated] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   useEffect(() => {
//     setIsAnimated(true);
//   }, []);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       navigate("/overview");
//     }
//   }, [navigate]);

//   const validateSignIn = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     setErrorMessage("");

//     const emailRegex = /^\w+@\w+\.\w+$/;
//     if (!emailRegex.test(email)) {
//       setErrorMessage("Please enter a valid email address.");
//       setIsLoading(false);
//       return;
//     }
//     if (password.length < 8) {
//       setErrorMessage("Password must be at least 8 characters long.");
//       setIsLoading(false);
//       return;
//     }

//     const loginData = { username: email.toLowerCase(), password };

//     try {
//       await login(loginData, navigate);
//     } catch (error) {
//       setIsLoading(false);

//       if (error.response) {
//         if (error.response.status === 400 || error.response.status === 401) {
//           setErrorMessage("Invalid email or password. Please try again.");
//         } else if (error.response.status >= 500) {
//           setErrorMessage("Server error. Please try again later.");
//         }
//       } else if (error.request) {
//         setErrorMessage("Network error. Check your connection and try again.");
//       } else {
//         setErrorMessage("An unexpected error occurred. Please try again.");
//       }

//       console.error("Sign-in error:", error.message);
//     }
//   };

//   return (
//     <div className="signup-container no-topnav-page">
//       <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
//         <img className="centered-logo" src={Logo} alt="Company logo" />
//         <h1 className="hello-text">Hello</h1>
//         <h2 className="welcome-text">
//           Login to your <br /> Ijeawele Account
//         </h2>
//       </div>
//       <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
//         <div className="form-card">
//           <h2>Sign In</h2>
//           <p>Welcome back to Ijeawele Investments</p>
//           <form onSubmit={validateSignIn}>
//             <TextInput
//               label="Email"
//               type="email"
//               id="email"
//               placeholder="Enter your email"
//               required
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <PasswordInput
//               id="password"
//               placeholder="Enter your password"
//               label="Password"
//               required
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />

//             <Link to="/forgotpassword" className="forgot-password">
//               Forgot Password?
//             </Link>

//             {errorMessage && <p className="error-message">{errorMessage}</p>}

//             {isLoading && (
//               <RevolvingDot
//                 type="RevolvingDot"
//                 color="#00BFFF"
//                 height={5}
//                 width={5}
//                 timeout={3000}
//               />
//             )}

//             <button type="submit">Sign In</button>
//           </form>

//           <p className="create-account-text">
//             Are you new to Ijeawele? <Link to="/">Create an Account</Link>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SignIn;


// import React, { useEffect, useState } from "react";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import Logo from "../Assets/logo.svg";
// import TextInput from "../Components/TextInput";
// import PasswordInput from "../Components/PasswordInput";
// import { RevolvingDot } from "react-loader-spinner";
// import "../Styles/SignUp.css";
// import { useAuth } from "../Components/AuthContext"; // Adjust the path as needed

// function SignIn() {
//   const [isAnimated, setIsAnimated] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { login } = useAuth();

//   useEffect(() => {
//     setIsAnimated(true);
//   }, []);

//   useEffect(() => {
//     const token = localStorage.getItem("token");

//     // Prevent redirect if the user is navigating to the sign-up page
//     if (token && location.pathname === "/signin") {
//       navigate("/overview");
//     }
//   }, [navigate, location.pathname]);

//   const validateSignIn = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     setErrorMessage("");

//     const emailRegex = /^\w+@\w+\.\w+$/;
//     if (!emailRegex.test(email)) {
//       setErrorMessage("Please enter a valid email address.");
//       setIsLoading(false);
//       return;
//     }
//     if (password.length < 8) {
//       setErrorMessage("Password must be at least 8 characters long.");
//       setIsLoading(false);
//       return;
//     }

//     const loginData = { username: email.toLowerCase(), password };

//     try {
//       await login(loginData, navigate);
//     } catch (error) {
//       setIsLoading(false);

//       if (error.response) {
//         if (error.response.status === 400 || error.response.status === 401) {
//           setErrorMessage("Invalid email or password. Please try again.");
//         } else if (error.response.status >= 500) {
//           setErrorMessage("Server error. Please try again later.");
//         }
//       } else if (error.request) {
//         setErrorMessage("Network error. Check your connection and try again.");
//       } else {
//         setErrorMessage("An unexpected error occurred. Please try again.");
//       }

//       console.error("Sign-in error:", error.message);
//     }
//   };

//   return (
//     <div className="signup-container no-topnav-page">
//       <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
//         <img className="centered-logo" src={Logo} alt="Company logo" />
//         <h1 className="hello-text">Hello</h1>
//         <h2 className="welcome-text">
//           Login to your <br /> Ijeawele Account
//         </h2>
//       </div>
//       <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
//         <div className="form-card">
//           <h2>Sign In</h2>
//           <p>Welcome back to Ijeawele Investments</p>
//           <form onSubmit={validateSignIn}>
//             <TextInput
//               label="Email"
//               type="email"
//               id="email"
//               placeholder="Enter your email"
//               required
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <PasswordInput
//               id="password"
//               placeholder="Enter your password"
//               label="Password"
//               required
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />

//             <Link to="/forgotpassword" className="forgot-password">
//               Forgot Password?
//             </Link>

//             {errorMessage && <p className="error-message">{errorMessage}</p>}

//             {isLoading && (
//               <RevolvingDot
//                 type="RevolvingDot"
//                 color="#00BFFF"
//                 height={5}
//                 width={5}
//                 timeout={3000}
//               />
//             )}

//             <button type="submit">Sign In</button>
//           </form>

//           <p className="create-account-text">
//             Are you new to Ijeawele? <Link to="/">Create an Account</Link>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SignIn;






import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Logo from "../Assets/logo.svg";
import TextInput from "../Components/TextInput";
import PasswordInput from "../Components/PasswordInput";
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";
import { useAuth } from "../Components/AuthContext";

function SignIn() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Redirect authenticated users to the dashboard
    if (isAuthenticated && location.pathname === "/signin") {
      navigate("/overview");
    }
  }, [isAuthenticated, navigate, location.pathname]);

  const validateSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    const emailRegex = /^\w+@\w+\.\w+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      setIsLoading(false);
      return;
    }

    const loginData = { username: email.toLowerCase(), password };

    try {
      await login(loginData, navigate);
    } catch (error) {
      setIsLoading(false);

      if (error.response) {
        if (error.response.status === 400 || error.response.status === 401) {
          setErrorMessage("Invalid email or password. Please try again.");
        } else if (error.response.status >= 500) {
          setErrorMessage("Server error. Please try again later.");
        }
      } else if (error.request) {
        setErrorMessage("Network error. Check your connection and try again.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }

      console.error("Sign-in error:", error.message);
    }
  };

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <img className="centered-logo" src={Logo} alt="Company logo" />
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">
          Login to your <br /> Ijeawele Account
        </h2>
      </div>
      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>Sign In</h2>
          <p>Welcome back to Ijeawele Investments</p>
          <form onSubmit={validateSignIn}>
            <TextInput
              label="Email"
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordInput
              id="password"
              placeholder="Enter your password"
              label="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Link to="/forgotpassword" className="forgot-password">
              Forgot Password?
            </Link>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {isLoading && (
              <RevolvingDot
                type="RevolvingDot"
                color="#00BFFF"
                height={5}
                width={5}
                timeout={3000}
              />
            )}

            <button type="submit">Sign In</button>
          </form>

          <p className="create-account-text">
            Are you new to Ijeawele? <Link to="/">Create an Account</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
