// import React, { useState, useEffect } from "react";
// import ProfileImageDisplay from "./ProfileImageDisplay";
// import ProfileImageUpload from "./ProfileImageUpload";
// import LoadingIndicator from "./LoadingIndicator";
// import { fetchUserData, uploadProfileImage } from "../Utilities/apiService";
// import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
// import "../Styles/ProfilePicture.css";

// function ProfilePicture() {
//   const [profileImage, setProfileImage] = useState(localStorage.getItem("profileImage") || ProfileImagePlaceholder);
//   const [isUploading, setIsUploading] = useState(false);
//   const [userId, setUserId] = useState(null);
//   const [previewImage, setPreviewImage] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsUploading(true);
//       try {
//         const data = await fetchUserData();
//         setUserId(data.id);
//         const imageUrl = data.profile_image ? `https://api.ijeaweleinvest.com${data.profile_image}` : ProfileImagePlaceholder;
//         setProfileImage(imageUrl);
//         if (data.profile_image) {
//           localStorage.setItem("profileImage", imageUrl);
//         }
//       } catch (err) {
//         setError("Failed to fetch user data. Please try again.");
//       } finally {
//         setIsUploading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (file && validateImage(file)) {
//       const previewUrl = URL.createObjectURL(file);
//       setPreviewImage(previewUrl);
//       setIsUploading(true);
//       try {
//         const updatedData = await uploadProfileImage(userId, file);
//         if (updatedData.profile_image) {
//           const imageUrl = `https://api.ijeaweleinvest.com${updatedData.profile_image}`;
//           setProfileImage(imageUrl);
//           localStorage.setItem("profileImage", imageUrl);
//           setPreviewImage(null);
//         } else {
//           throw new Error("No image URL returned from the server.");
//         }
//       } catch (err) {
//         setError("Failed to upload image. Please try again.");
//       } finally {
//         setIsUploading(false);
//       }
//     }
//   };

//   const validateImage = (file) => {
//     const validTypes = ["image/jpeg", "image/png", "image/gif"];
//     const maxSize = 5 * 1024 * 1024; // 5MB
//     if (!validTypes.includes(file.type)) {
//       setError("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
//       return false;
//     }
//     if (file.size > maxSize) {
//       setError("File size exceeds 5MB. Please upload a smaller image.");
//       return false;
//     }
//     setError(null);
//     return true;
//   };

//   return (
//     <div className="profile-picture">
//       {isUploading ? (
//         <LoadingIndicator />
//       ) : (
//         <ProfileImageDisplay
//           profileImage={profileImage}
//           previewImage={previewImage}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = ProfileImagePlaceholder;
//           }}
//         />
//       )}
//       <ProfileImageUpload handleImageChange={handleImageChange} />
//       {error && <div className="error-message">{error}</div>}
//     </div>
//   );
// }

// export default ProfilePicture;



import React, { useState } from "react";
import ProfileImageDisplay from "./ProfileImageDisplay";
import ProfileImageUpload from "./ProfileImageUpload";
import LoadingIndicator from "./LoadingIndicator";
import { uploadProfileImage } from "../Utilities/apiService";
import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
import "../Styles/ProfilePicture.css";
import { useAuth } from "../Components/AuthContext"; // Import the Auth context

function ProfilePicture() {
  const { user } = useAuth(); // Get the current user from AuthContext
  const [profileImage, setProfileImage] = useState(
    user?.profile_image
      ? `https://api.ijeaweleinvest.com${user.profile_image}`
      : ProfileImagePlaceholder
  );
  const [isUploading, setIsUploading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && validateImage(file)) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      setIsUploading(true);
      try {
        const updatedData = await uploadProfileImage(user?.id, file);
        if (updatedData.profile_image) {
          const imageUrl = `https://api.ijeaweleinvest.com${updatedData.profile_image}`;
          setProfileImage(imageUrl);
          setPreviewImage(null);
        } else {
          throw new Error("No image URL returned from the server.");
        }
      } catch (err) {
        setError("Failed to upload image. Please try again.");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const validateImage = (file) => {
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (!validTypes.includes(file.type)) {
      setError("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
      return false;
    }
    if (file.size > maxSize) {
      setError("File size exceeds 5MB. Please upload a smaller image.");
      return false;
    }
    setError(null);
    return true;
  };

  return (
    <div className="profile-picture">
      {isUploading ? (
        <LoadingIndicator />
      ) : (
        <ProfileImageDisplay
          profileImage={profileImage}
          previewImage={previewImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ProfileImagePlaceholder;
          }}
        />
      )}
      <ProfileImageUpload handleImageChange={handleImageChange} />
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default ProfilePicture;
