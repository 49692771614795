import React, { useState, useEffect } from "react";
import axios from "axios";

function PinCreation() {
  const [pinInput, setPinInput] = useState(["", "", "", ""]);
  const [confirmPinInput, setConfirmPinInput] = useState(["", "", "", ""]);
  const [showPinInput, setShowPinInput] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [isPinDisabled, setIsPinDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState(null); // Store user ID

  // Fetch current user data and check PIN status when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const data = response.data;
        setUserId(data.id); // Store user ID
        setIsPinDisabled(data.has_transaction_pin); // Disable PIN creation if already set
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  // Handle PIN change
  const handlePinChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1) {
      const newPinInput = [...pinInput];
      newPinInput[index] = value;
      setPinInput(newPinInput);

      if (index < 3) {
        document.getElementById(`pin-${index + 1}`).focus(); // Focus on next input
      } else {
        setShowConfirmPin(true); // Show confirm PIN input after 4 digits are filled
        setTimeout(() => setShowPinInput(false), 300); // Hide initial PIN input when confirming
      }
    }
  };

  // Handle PIN confirmation
  const handleConfirmPinChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1) {
      const newConfirmPinInput = [...confirmPinInput];
      newConfirmPinInput[index] = value;
      setConfirmPinInput(newConfirmPinInput);

      if (index < 3) {
        document.getElementById(`confirm-pin-${index + 1}`).focus(); // Focus on next confirm input
      }
    }
  };

  // Handle submit PIN
  const submitPin = async () => {
    const pin = pinInput.join("");
    const confirmPin = confirmPinInput.join("");

    if (!userId) {
      setErrorMessage("User ID is missing. Unable to create PIN.");
      return;
    }

    if (pin !== confirmPin) {
      setErrorMessage("PINs do not match. Please try again.");
      setPinInput(["", "", "", ""]);
      setConfirmPinInput(["", "", "", ""]);
      setShowConfirmPin(false);
      setShowPinInput(true); // Show PIN input again
      return;
    }

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `https://api.ijeaweleinvest.com/users/${userId}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ transaction_pin: pin }),
        }
      );

      if (response.ok) {
        alert("Transaction PIN created successfully!");
        setIsPinDisabled(true); // Disable PIN input after success
        setShowPinInput(false); // Hide PIN input after success
        setErrorMessage(""); // Clear error message
      } else {
        const errorData = await response.json();
        setErrorMessage(`Failed to create PIN: ${errorData.detail}`);
      }
    } catch (error) {
      console.error("Error creating PIN:", error);
      setErrorMessage("An error occurred while creating your PIN.");
    }
  };

  return (
    <div className="pin-input-container">
      <button
        className="toggle-pin-button"
        onClick={() => setShowPinInput(true)}
        disabled={isPinDisabled}
      >
        {isPinDisabled ? "PIN Created" : "Create Transaction PIN"}
      </button>

      {showPinInput && (
        <div className="pin-input-fields">
          {pinInput.map((digit, index) => (
            <input
              key={index}
              type="password"
              maxLength="1"
              value={digit}
              id={`pin-${index}`}
              className="pin-input-item"
              onChange={(e) => handlePinChange(e, index)}
            />
          ))}
        </div>
      )}

      {showConfirmPin && (
        <div className="confirm-pin-input-fields">
          <h3>Confirm PIN</h3>
          {confirmPinInput.map((digit, index) => (
            <input
              key={index}
              type="password"
              maxLength="1"
              value={digit}
              id={`confirm-pin-${index}`}
              className="pin-input-item"
              onChange={(e) => handleConfirmPinChange(e, index)}
            />
          ))}
          <button onClick={submitPin} className="submit-pin-button">
            Confirm and Set PIN
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
}

export default PinCreation;
