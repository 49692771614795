import React, { useState, useEffect } from "react";
import axios from "axios";

const NextOfKin = ({ userId, showMessage }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    relationship: "",
  });
  const [loading, setLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    const checkNextOfKinStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          showMessage("Authentication error. Please log in again.", "error");
          return;
        }

        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const user = response.data;

        // Log the next_of_kin field to verify its value
        console.log("Next of Kin field:", user.next_of_kin);

        // Check next_of_kin status
        if (user.next_of_kin === null) {
          setFormDisabled(false);
        } else {
          setFormDisabled(true);
        }
      } catch (error) {
        console.error("Error checking Next of Kin status:", error);
        showMessage("Failed to check Next of Kin status. Please try again.", "error");
      }
    };

    checkNextOfKinStatus();
  }, [userId, showMessage]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const { first_name, last_name, email, address, relationship } = formData;
    if (!first_name || !last_name || !email || !address || !relationship) {
      setMessage("Please fill in all fields.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType("");
      }, 7000);
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "https://api.ijeaweleinvest.com/next-of-kin/",
        { ...formData, profile: userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage("Next of Kin details submitted successfully.");
      setMessageType("success");
      setFormDisabled(true);
      setTimeout(() => {
        setMessage(null);
        setMessageType("");
      }, 7000);
    } catch {
      setMessage("Submission failed. Please try again.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType("");
      }, 7000);
    } finally {
      setLoading(false);
    }
  };

  if (formDisabled) {
    return (
      <div className="kyc-form">
        <h5>Next of Kin Information</h5>
        <p>Next of Kin details submitted successfully.</p>
      </div>
    );
  }

  return (
    <div className="kyc-form">
      <h5>Next of Kin Information</h5>

      {message && <p className={`kyc-message ${messageType}`}>{message}</p>}

      {["first_name", "last_name", "email", "address", "relationship"].map(
        (field) => (
          <input
            key={field}
            name={field}
            placeholder={field.replace("_", " ")}
            value={formData[field]}
            onChange={handleChange}
            disabled={formDisabled}
            className="kyc-input"
          />
        )
      )}

      <button
        onClick={handleSubmit}
        className="id-submit-button"
        disabled={formDisabled || loading}
      >
        {loading ? "Submitting..." : "Submit"}
      </button>
    </div>
  );
};

export default NextOfKin;
