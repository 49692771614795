import { useState } from "react";

function DepositOptions({ setDepositOption, setIsDepositClicked, setDepositAmount }) {
  const [amountInput, setAmountInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleDepositSelection = () => {
    const amount = Number(amountInput.replace(/₦|,/g, ""));
    if (isNaN(amount) || amount <= 0) {
      setErrorMessage("Please enter a valid deposit amount.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    setDepositAmount(amount);

    if (amount > 500000) {
      setDepositOption("manual");
    } else {
      setDepositOption("paystack");
    }

    setIsDepositClicked(false);
  };

  return (
    <div className="overlay">
      <div className="deposit-popup">
        <h3>Enter Deposit Amount</h3>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <input
          type="text"
          placeholder="Enter amount"
          value={
            amountInput !== ""
              ? `₦${Number(amountInput.replace(/₦|,/g, "")).toLocaleString("en-NG")}`
              : ""
          }
          onChange={(e) => {
            const value = e.target.value.replace(/₦|,/g, "");
            if (!isNaN(value) && value >= 0) {
              setAmountInput(value);
            }
          }}
          className="deposit-input"
        />
        <button onClick={handleDepositSelection} className="confirm-deposit-btn">
          Proceed
        </button>
        <button
          onClick={() => setIsDepositClicked(false)}
          className="cancel-btn"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default DepositOptions;
