import "../Styles/BalanceOverview.css";
import { IoMdEyeOff } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import PaystackDeposit from "../Components/PaystackDeposit";
import ManualDeposit from "../Components/ManualDeposit";
import DepositOptions from "../Components/DepositOptions";

function BalanceOverview({ showButtons = true }) {
  const [balanceVisibility, setBalanceVisibility] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [balance, setBalance] = useState(0);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDepositClicked, setIsDepositClicked] = useState(false);
  const [depositOption, setDepositOption] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);

  const companyBankDetails = {
    accountName: "Ijeawele Investments",
    accountNumber: "1234567890",
    bankName: "Example Bank",
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token found. Please log in.");

        const response = await fetch(
          "https://api.ijeaweleinvest.com/users/current_user/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        setUserEmail(data.email);
        setBalance(data.wallet.balance);
        setVerificationStatus(data.verification_status);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleWithdrawClick = (e) => {
    if (!verificationStatus) {
      e.preventDefault();
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 5000);
    }
  };

  return (
    <div className="balance-overview">
      <div className="balance-info">
        <h3>Total Balance</h3>
        <h2>{balanceVisibility ? `₦${balance}` : "*****"}</h2>
        {showButtons && (
          <div className="balance-actions">
            <div
              className="withdrawal-btn-container"
              onClick={handleWithdrawClick}
            >
              <Link to={verificationStatus ? "/withdrawal" : "#"}>
                <button
                  className={`withdraw-btn ${
                    !verificationStatus ? "disabled" : ""
                  }`}
                >
                  Withdraw
                </button>
              </Link>
            </div>
            <button
              onClick={() => setIsDepositClicked(true)}
              className="deposit-btn"
            >
              Deposit
            </button>
          </div>
        )}
      </div>

      <div className="balance-toggle">
        <span
          className="balance-icon"
          onClick={() => setBalanceVisibility((prev) => !prev)}
        >
          {balanceVisibility ? <FaEye /> : <IoMdEyeOff />}
        </span>
      </div>

      {isDepositClicked && (
        <DepositOptions
          setDepositOption={setDepositOption}
          setIsDepositClicked={setIsDepositClicked}
          setDepositAmount={setDepositAmount}
        />
      )}

      {depositOption === "paystack" && (
        <PaystackDeposit
          userEmail={userEmail}
          depositAmount={depositAmount}
          setDepositOption={setDepositOption}
        />
      )}

      {depositOption === "manual" && (
        <ManualDeposit
          companyBankDetails={companyBankDetails}
          depositAmount={depositAmount}
          setDepositOption={setDepositOption}
        />
      )}

      {showMessage && (
        <p className="warning-message">
          You need to get verified to be able to withdraw.
        </p>
      )}
    </div>
  );
}

export default BalanceOverview;

