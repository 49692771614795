import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import BalanceOverview from "../Components/BalanceOverview";
import TextInput from "../Components/TextInput";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import "../Styles/Withdrawal.css";
import LoadingDot from "../Components/LoadingDot";

function Withdrawal() {
  const [inProp, setInProp] = useState(false);
  const withdrawalContainerRef = useRef(null);

  // States for form fields
  const [userName, setUserName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [balance, setBalance] = useState(null);
  const [userid, setUserid] = useState("");
  const [pin, setPin] = useState("");

  // States for validation errors
  const [errorAccountNumber, setErrorAccountNumber] = useState("");
  const [errorAmount, setErrorAmount] = useState("");

  // Loading and confirmation state
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorPin, setErrorPin] = useState("");

  // Fetch current user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const data = response.data;
        setUserid(data.id);
        setUserName(data.name);
        setBalance(data.wallet.balance);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  // Fetch bank codes when component mounts
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/wallet/fetch_banks/"
        );
        setBanks(response.data);
      } catch (error) {
        console.error("Error fetching bank codes:", error);
      }
    };
    fetchBanks();
  }, []);

  // Verify account name based on bank code and account number
  const confirmReceiver = async () => {
    if (!bankCode || accountNumber.length < 10) {
      setErrorAccountNumber("Please provide a valid bank and account number.");
      return;
    }

    setVerifying(true);
    try {
      const response = await axios.post(
        "https://api.ijeaweleinvest.com/wallet/verify-account/",
        {
          account_number: accountNumber,
          bank_code: bankCode,
        }
      );
      setAccountName(response.data.account_name);
      setErrorAccountNumber(""); // Clear any previous errors
    } catch (error) {
      console.error("Error confirming receiver:", error);
      setErrorAccountNumber(
        "Unable to confirm receiver. Please check details."
      );
    } finally {
      setVerifying(false);
    }
  };

  // const validateWithdrawalForm = () => {
  //   setErrorAccountNumber("");
  //   setErrorAmount("");
  //   setErrorPin("");

  //   let isValid = true;

  //   if (!accountName) {
  //     setErrorAccountNumber("Please confirm the receiver before proceeding.");
  //     isValid = false;
  //   }

  //   if (amount > balance) {
  //     setErrorAmount("Withdrawal amount cannot exceed your balance.");
  //     isValid = false;
  //   }

  //   if (!pin) {
  //     setErrorPin("Transaction PIN is required.");
  //     isValid = false;
  //   }

  //   return isValid;
  // };
  const validateWithdrawalForm = () => {
    setErrorAccountNumber("");
    setErrorAmount("");
    setErrorPin("");

    let isValid = true;

    if (!accountName) {
      setErrorAccountNumber("Please confirm the receiver before proceeding.");
      isValid = false;
    }

    if (!amount) {
      setErrorAmount("Withdrawal amount is required.");
      isValid = false;
    } else if (amount > balance) {
      setErrorAmount("Withdrawal amount cannot exceed your balance.");
      isValid = false;
    }

    if (!pin) {
      setErrorPin("Transaction PIN is required.");
      isValid = false;
    }

    return isValid;
  };

  const handleWithdrawal = async (e) => {
    e.preventDefault();
    if (!userid) {
      console.error("User ID is undefined. Cannot proceed.");
      return;
    }

    if (!validateWithdrawalForm()) return;

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const payload = {
        amount,
        account_number: accountNumber,
        bank_code: bankCode,
        user: userid,
        pin, // Include pin in the payload
      };

      await axios.post(
        "https://api.ijeaweleinvest.com/wallet/request_withdrawal/",
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setConfirmationMessage(
        "Withdrawal request sent successfully for approval."
      );
      setShowConfirmation(true);

      // Automatically hide the confirmation after 7 seconds
      setTimeout(() => {
        setShowConfirmation(false);
      }, 7000);

      // Reset form fields
      setAccountName("");
      setAccountNumber("");
      setAmount("");
      setBankCode("");
      setPin("");
    } catch (error) {
      console.error("Error submitting withdrawal request:", error);

      if (error.response && error.response.status === 401) {
        alert("Your session has expired. Please log in again.");
      } else {
        alert("There was an error processing your request.");
      }
    } finally {
      setLoading(false);
    }
  };

  // message timer
  // useEffect(() => {
  //   if (confirmationMessage || errorAccountNumber || errorAmount) {
  //     const timer = setTimeout(() => {
  //       setConfirmationMessage("");
  //       setErrorAccountNumber("");
  //       setErrorAmount("");
  //     }, 5000); // 7 seconds

  //     return () => clearTimeout(timer); // Cleanup timer
  //   }
  // }, [confirmationMessage, errorAccountNumber, errorAmount]);
  useEffect(() => {
    if (confirmationMessage || errorAccountNumber || errorAmount || errorPin) {
      const timer = setTimeout(() => {
        setConfirmationMessage("");
        setErrorAccountNumber("");
        setErrorAmount("");
        setErrorPin(""); // Clear transaction PIN error after 5 minutes
      }, 5000); // 5 minutes in milliseconds

      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [confirmationMessage, errorAccountNumber, errorAmount, errorPin]);

  return (
    <Layout>
      <CSSTransition
        in={inProp}
        timeout={1000}
        classNames="fade"
        nodeRef={withdrawalContainerRef}
      >
        <div ref={withdrawalContainerRef} className="withdrawal-container">
          <BalanceOverview showButtons={false} />
          <div className="profile-header">
            <p>
              Account <span>Details</span>
            </p>
          </div>

          <form className="account-details" onSubmit={handleWithdrawal}>
            {/* Bank select dropdown */}
            <div className="input-wrapper">
              <label htmlFor="bankCode">Select Bank</label>
              <select
                id="bankCode"
                value={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                required
                style={{
                  border: "none",
                  height: "46px",
                  maxWidth: "370px",
                  padding: "10px",
                  borderRadius: "8px",
                  background: "#f0eeee",
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              >
                <option value="" disabled>
                  -- Select Bank --
                </option>
                {banks.map((bank, index) => (
                  <option key={`${bank.code}-${index}`} value={bank.code}>
                    {bank.name}
                  </option>
                ))}
              </select>
            </div>

            <TextInput
              label="Account no"
              id="accountNumber"
              type="text"
              value={accountNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "").slice(0, 10);
                setAccountNumber(value);
              }}
              error={errorAccountNumber}
            />

            {!accountName && (
              <div className="button-wrapper">
                <button
                  type="button"
                  onClick={confirmReceiver}
                  disabled={verifying}
                  // className="verify-button"
                  className="withdraw-button"
                >
                  {verifying ? "Confirming..." : "Confirm Receiver"}
                </button>
              </div>
            )}

            {accountName && (
              <div className="account-name">
                {/* <strong>Receiver's Name:</strong> {accountName} */}
                <span>Receiver's Name</span>: <strong>{accountName}</strong>
              </div>
            )}

            {/* Conditional rendering for amount and pin inputs */}
            {accountName && (
              <>
                <TextInput
                  label="Amount"
                  id="amount"
                  type="text"
                  value={amount}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setAmount(value);
                  }}
                  error={errorAmount}
                />
                <TextInput
                  label="Transaction PIN"
                  id="pin"
                  type="password"
                  value={pin}
                  maxLength="4"
                  onChange={(e) =>
                    setPin(e.target.value.replace(/\D/g, "").slice(0, 4))
                  }
                  error={errorPin}
                />

                <div className="button-wrapper">
                  <button
                    className="withdraw-button"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Withdraw"}
                  </button>
                </div>
              </>
            )}

            {/* error message */}
            {confirmationMessage && (
              <div className="message success">{confirmationMessage}</div>
            )}

            {loading && <LoadingDot />}
          </form>
        </div>
      </CSSTransition>
    </Layout>
  );
}

export default Withdrawal;
