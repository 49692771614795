
import Sidebar from "./Sidebar";
import Header from "./Header";
import LayoutCSS from "../Styles/Layout.css";
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom"; // Outlet to render child components


function Layout({ children }) {
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    window.innerWidth > 768
  );

  const onToggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarVisible(true); // Show sidebar on large screens
      } else {
        setIsSidebarVisible(false); // Hide sidebar on small screens
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header onToggleSidebar={onToggleSidebar} />
      <div className="layout-container">
        <Sidebar isVisible={isSidebarVisible} />
        <main className="content-wrapper">{children}</main>
      </div>
    </>
  );
}

export default Layout;
